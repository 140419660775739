<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.proje.liste.title") }}
      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.yeniKaydet')"
          :open-delay="500"
          placement="top"
          v-if="
            yetkiTuru == 1 ||
            (yetkiTuru == 2 && yetkiListesi.includes('YK-projeC100'))
          "
        >
          <el-button
            v-on:click="routeProje('Proje Tanımla')"
            size="mini"
            type="text"
            icon="el-icon-plus"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            style="margin-left: 15px !important"
            type="text"
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading || stateLoading"
      :element-loading-text="
        loading
          ? $t('src.views.apps.proje.liste.loading')
          : $t('src.views.apps.proje.liste.stateLoading')
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row class="mt-0 mb-10">
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div>
            <el-radio-group
              size="small"
              v-on:change="handleChange($event)"
              v-model="radio"
              fill="#E5F5F9"
              text-color="gray"
              :disabled="searchLoading"
            >
              <el-radio-button label="1">
                <i
                  class="mdi mdi-domain mdi-18px"
                  style="color: #13ce66; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="selectIcon === '1'">{{
                    $t("src.views.apps.proje.liste.active")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="0">
                <i
                  class="mdi mdi-domain mdi-18px"
                  style="color: #ec205f; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="selectIcon === '0'">{{
                    $t("src.views.apps.proje.liste.passive")
                  }}</label>
                </transition>
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <br v-if="!isDesktop" />
        <br v-if="!isDesktop" />
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <ul
              :class="isDesktop ? 'islemler' : 'islemler2'"
              v-if="
                yetkiTuru == '1' ||
                (yetkiTuru == '2' &&
                  (yetkiListesi.includes('YK-projeU300') ||
                    yetkiListesi.includes('YK-projeR100')))
              "
            >
              <li class="musteri">
                <i class="mdi mdi-eye"></i>
                {{ $t("src.views.apps.proje.liste.table.showMusteriPage") }}
              </li>
              <li class="duzenle">
                <i class="mdi mdi-circle-edit-outline"></i>
                {{ $t("src.views.apps.proje.liste.table.editProject") }}
              </li>
              <li v-if="selectIcon === '1'" class="sil">
                <i class="mdi mdi-trash-can"></i>
                {{ $t("src.views.apps.proje.liste.table.setPassive") }}
              </li>
              <li v-else class="aktif">
                <i class="mdi mdi-play"></i>
                {{ $t("src.views.apps.proje.liste.table.setActive") }}
              </li>
            </ul>
          </el-col>
        </el-col>
      </el-row>
      <el-table
        v-loading="searchLoading"
        border
        stripe
        :data="projeList"
        style="width: 100%"
        :element-loading-text="'Projeler Aranıyor. Lütfen Bekleyiniz.'"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
      >
        <el-table-column
          type="expand"
          v-if="
            yetkiTuru == '1' ||
            (yetkiTuru == '2' && yetkiListesi.includes('YK-projeR100'))
          "
        >
          <template slot-scope="props">
            <div class="box grow">
              <blockquote
                :key="indexMethod(props.$index)"
                class="icTablo"
                v-if="props.row.sozlesmeler.length > 0"
              >
                <el-table
                  :data="props.row.sozlesmeler"
                  style="width: 100%; padding: 5px; margin: 5px"
                  class="icTablo"
                >
                  <el-table-column
                    :label="$t('src.views.apps.proje.liste.detail.sozlesmeID')"
                    width="auto"
                    style="color: #13ce66"
                  >
                    <template slot-scope="scope">{{
                      scope.row.sozlesmeID
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    :label="
                      $t('src.views.apps.proje.liste.detail.sozlesmeBaslik')
                    "
                    width="auto"
                  >
                    <template slot-scope="scope">{{
                      scope.row.sozlesmeBaslik
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('src.views.apps.proje.liste.detail.yuzdeDeger')"
                    width="auto"
                  >
                    <template slot-scope="scope">{{
                      scope.row.yuzdeDeger
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    :label="
                      $t('src.views.apps.proje.liste.detail.baslangicSuresi')
                    "
                    width="auto"
                  >
                    <template slot-scope="scope">{{
                      scope.row.baslangicSuresi
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('src.views.apps.proje.liste.detail.isSuresi')"
                    width="auto"
                  >
                    <template slot-scope="scope">{{
                      scope.row.isSuresi
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    :label="
                      $t('src.views.apps.proje.liste.detail.baslamaTarihi')
                    "
                    width="auto"
                  >
                    <template slot-scope="scope"
                      >{{
                        new Date(scope.row.baslamaTarihi).toLocaleDateString()
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('src.views.apps.proje.liste.detail.bitisTarihi')"
                    width="auto"
                  >
                    <template slot-scope="scope"
                      >{{
                        new Date(scope.row.isTeslimTarihi).toLocaleDateString()
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('src.views.apps.proje.liste.table.islem')"
                    width="150"
                    align="right"
                    v-if="yetkiTuru == 2"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        content="Kapsam Sözleşmelere Git"
                        :open-delay="500"
                        placement="bottom"
                      >
                        <el-button
                          @click="detayPage(scope.row.projeID)"
                          type="text"
                          class="islem-button"
                          style="
                            color: #006cae;
                            margin-left: 0px !important;
                            margin-right: 5px;
                          "
                          icon="mdi mdi-poll mdi-24px"
                        ></el-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </blockquote>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header"> </template>
          <el-table-column
            :label="$t('src.views.apps.genel.sira')"
            type="index"
            :index="indexMethod"
            width="60"
          >
          </el-table-column>
        </el-table-column>
        <!--
        <el-table-column>
            <template slot="header">
            </template>
            <el-table-column
                prop="projeID"
                :label='$t("src.views.apps.proje.liste.table.id")'
                width="74">
            </el-table-column>
        </el-table-column> -->
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="projeAra"
              :placeholder="
                $t('src.views.apps.proje.liste.search.searchCompanyName')
              "
              v-model="firmaAdiAra"
              v-debounce:500ms="projeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            prop="musteriAdi"
            :label="$t('src.views.apps.musteri.liste.table.firmaAdi')"
            width="auto"
            :min-width="150"
          >
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.musteriAdi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="projeAra"
              :placeholder="$t('src.views.apps.proje.liste.search.searchName')"
              v-model="adAra"
              v-debounce:500ms="projeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            prop="projeBaslik"
            :label="$t('src.views.apps.proje.liste.table.projeAdi')"
            width="auto"
            :min-width="180"
          >
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.projeBaslik }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="projeAra"
              :placeholder="$t('src.views.apps.proje.liste.search.searchArea')"
              v-model="alanAra"
              v-debounce:500ms="projeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            prop="alan"
            :label="$t('src.views.apps.proje.liste.table.alan') + ' (m²)'"
            width="auto"
            :min-width="150"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="projeAra"
              :placeholder="
                $t('src.views.apps.proje.liste.search.searchIsland')
              "
              v-model="adaAra"
              v-debounce:500ms="projeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            prop="ada"
            :label="$t('src.views.apps.proje.liste.table.ada')"
            width="auto"
            :min-width="117"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="projeAra"
              :placeholder="
                $t('src.views.apps.proje.liste.search.searchParcel')
              "
              v-model="parselAra"
              v-debounce:500ms="projeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            prop="parsel"
            :label="$t('src.views.apps.proje.liste.table.parsel')"
            width="auto"
            :min-width="130"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="projeAra"
              :placeholder="$t('src.views.apps.proje.liste.search.searchPlace')"
              v-model="konumAra"
              v-debounce:500ms="projeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column :label="'Konum'" width="270">
            <template slot-scope="scope">
              {{ scope.row.ulkeAdi }}, {{ scope.row.ilBaslik }},
              {{ scope.row.ilceBaslik }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker
              @change="projeAra"
              :placeholder="
                $t('src.views.apps.proje.liste.search.searchStartDate')
              "
              v-model="baslangicTarihiAra"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              size="mini"
              class="select-wide"
              type="date"
            ></el-date-picker>
          </template>
          <el-table-column
            prop="baslangicTarihi"
            :label="$t('src.views.apps.proje.liste.table.baslangicTarihi')"
            width="auto"
            min-width="170"
          >
            <template slot-scope="scope">
              {{ new Date(scope.row.baslangicTarihi).toLocaleDateString() }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker
              @change="projeAra"
              :placeholder="
                $t('src.views.apps.proje.liste.search.searchEndDate')
              "
              v-model="bitisTarihiAra"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              size="mini"
              class="select-wide"
              type="date"
            ></el-date-picker>
          </template>
          <el-table-column
            prop="bitisTarihi"
            :label="$t('src.views.apps.proje.liste.table.bitisTarihi')"
            width="auto"
            min-width="170"
          >
            <template slot-scope="scope">
              {{ new Date(scope.row.bitisTarihi).toLocaleDateString() }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-if="
            yetkiTuru == '1' ||
            (yetkiTuru == '2' && yetkiListesi.includes('YK-projeU300'))
          "
        >
          <el-table-column
            width="130"
            :label="$t('src.views.apps.genel.islem')"
          >
            <template slot-scope="scope">
              <div class="islem-area">
                <el-tooltip
                  :content="
                    $t('src.views.apps.proje.liste.table.showMusteriPage')
                  "
                  :open-delay="500"
                  placement="bottom"
                >
                  <el-button
                    @click="musteriPage(scope.row.musteriID, scope.row)"
                    type="text"
                    class="islem-button"
                    style="color: #5e82ba; margin-left: 15px !important"
                    icon="mdi mdi-eye mdi-24px"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('src.views.apps.proje.liste.table.editProject')"
                  :open-delay="500"
                  placement="bottom"
                >
                  <el-button
                    @click="updatePage(scope.row)"
                    type="text"
                    class="islem-button"
                    style="color: #f7ba2a; margin-left: 0 !important"
                    icon="mdi mdi-circle-edit-outline mdi-24px"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('src.views.apps.proje.liste.table.setPassive')"
                  :open-delay="500"
                  v-if="selectIcon === '1'"
                  placement="bottom"
                >
                  <el-button
                    @click="changeProjeDurum(scope.row, '0')"
                    class="buttonDel"
                    style="margin-left: 0 !important"
                    type="text"
                    icon="mdi mdi-trash-can mdi-24px"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('src.views.apps.proje.liste.table.setActive')"
                  :open-delay="500"
                  v-if="selectIcon === '0'"
                  placement="bottom"
                >
                  <el-button
                    @click="changeProjeDurum(scope.row, '1')"
                    type="text"
                    style="margin-left: 0 !important"
                    class="islem-button-play islem-button ml-15"
                    icon="mdi mdi-play mdi-24px"
                  ></el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div
        v-if="projeList.length == [] && isDesktop"
        style="
          width: 100%;
          height: 50px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        {{ $t("src.views.apps.messages.noData") }}
      </div>
      <div v-if="projeList.length == [] && !isDesktop">
        <!--        {{ open() }}-->
      </div>
      <el-pagination
        background
        layout="sizes,prev, pager, next, jumper, total"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :page-sizes="[20, 50, 100]"
        :current-page.sync="page"
        :next-click.sync="page"
        :prev-click.sync="page"
        @next-click="pageChange"
        @prev-click="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="
        editDialogData.adSoyad +
        ' ' +
        $t('src.views.apps.genel.guncelleCustomerTitle')
      "
      :visible.sync="editDialog"
      width="40%"
      center
    >
      <el-row
        v-loading="editDialogLoading"
        :element-loading-text="'Customer is Updating. Please Wait'"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
      >
        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
          <el-form
            status-icon
            :model="projeForm"
            :rules="rulesProje"
            ref="projeForm"
            class="demo-ruleForm"
          >
            <el-form-item
              :label="$t('src.views.apps.proje.liste.table.nameSurname')"
              prop="adSoyad"
              >:
              <el-input
                v-model="projeForm.adSoyad"
                :placeholder="
                  $t('src.views.apps.proje.liste.table.nameSurnamePlace')
                "
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('src.views.apps.proje.liste.table.email')"
              prop="eposta"
              >:
              <el-input
                v-model="projeForm.eposta"
                :placeholder="$t('src.views.apps.proje.liste.table.emailPlace')"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('src.views.apps.proje.liste.table.telephone')"
              prop="telefon"
              >:
              <el-input
                v-model="projeForm.telefon"
                v-mask="'(5##)### ## ##'"
                placeholder="+90(5XX)XXX XX XX"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <div style="float: right; padding-right: 15px">
            <el-button type="danger" @click="vazgec()">{{
              $t("src.views.apps.genel.vazgec")
            }}</el-button>
            <el-button type="primary" @click="Guncelle('projeForm')"
              >{{ $t("src.views.apps.genel.guncelle") }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import projeService from "../../../WSProvider/ProjeService";
import EventBus from "@/components/event-bus";
import notification from "../../../notification";
import functions from "../../../functions";
import kapsamService from "../../../WSProvider/KapsamService";

var moment = require("moment");

export default {
  name: "ProjeListesi",
  data() {
    return {
      isDesktop: false,
      loading: false,
      stateLoading: false,
      searchLoading: false,
      editDialogLoading: false,
      editDialog: false,
      editDialogData: {},
      projeList: [],
      sozlesmeler: [],
      radio: "1",
      projeForm: {
        eposta: "",
        adSoyad: "",
        telefon: "",
        tc: "",
        dogumTarihi: "",
        sozlesmeID: "",
      },
      rulesProje: {},
      selectIcon: "1",
      sizes: 20,
      page: 0,
      postPage: 0,
      total: 4,
      firmaAdiAra: "",
      adAra: "",
      alanAra: "",
      adaAra: "",
      parselAra: "",
      konumAra: "",
      ilAra: "",
      ilceAra: "",
      baslangicTarihiAra: "",
      bitisTarihiAra: "",
    };
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      this.yoneticiID = this.$store.getters.getYoneticiID;
    }
    EventBus.$on("projelistesi", () => {
      this.refreshPage();
    });
  },
  mounted() {
    if (this.yetkiTuru == 1) {
      this.getProjeListesi();
    }
    if (this.yetkiTuru == 2) {
      this.getBirimYoneticininProjeListesi();
    }
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },
    open() {
      setTimeout(() => {
        this.$alert("Gösterilecek veri bulunmamaktadır", "Uyarı!", {
          confirmButtonText: "OK",
        });
      }, 0);
    },
    projeAra() {
      this.postPage = 0;
      this.page = 1;
      this.dateVisible = false;
      //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
      if (
        this.firmaAdiAra.length > 0 ||
        this.adAra.length > 0 ||
        this.alanAra.length > 0 ||
        this.adaAra.length > 0 ||
        this.parselAra.length > 0 ||
        this.konumAra.length > 0 ||
        this.baslangicTarihiAra ||
        this.bitisTarihiAra
      ) {
        try {
          this.searchLoading = true;
          //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
          projeService
            .projeFilter(
              this.firmaAdiAra,
              this.adAra,
              this.alanAra,
              this.adaAra,
              this.parselAra,
              this.konumAra,
              this.baslangicTarihiAra,
              this.bitisTarihiAra,
              this.selectIcon,
              this.postPage,
              this.sizes
            )
            .then((response) => {
              if (response.status == 200) {
                this.projeList = response.data;
                this.total = response.count;
              }
              localStorage.setItem("userDataBGSurec", response.token);
              this.searchLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404 || error.status == 503) {
                  this.projeList = [];
                }
              } else {
                //notification.Status(503, this)
              }
              this.searchLoading = false;
            });
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false;
        }
      } else {
        if (this.yetkiTuru == 1) {
          this.getProjeListesi();
        }
        if (this.yetkiTuru == 2) {
          this.getBirimYoneticininProjeListesi();
        }
        // this.getProjeSayisi();
      }
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;

      // this.getProjeSayisi();
      if (this.yetkiTuru == 1) {
        this.getProjeListesi();
      }
      if (this.yetkiTuru == 2) {
        this.getBirimYoneticininProjeListesi();
      }
    },
    detayPage(projeID) {
      //console.log("projeID")
      this.$store.commit("changeProjeDetayData", projeID);
      functions.routeSayfa("Kapsam Sözlesmeler", this);
    },
    musteriPage(musteriID, proje) {
      if (
        this.yetkiTuru == 1 ||
        (this.yetkiTuru == 2 && this.yetkiListesi.includes("YK-musteriP100"))
      ) {
        this.$store.commit("changeMusteriDetayData", musteriID);
        this.$store.commit("changeProjeDetayData", proje);
        functions.routeSayfa("Müşteri Rapor", this);
      }
    },
    Guncelle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          //adSoyad, mail, telefon, projeID
          this.$confirm(
            "Projeyi güncellemek istediğinizden emin misiniz?",
            this.$t("src.views.apps.genel.uyari"),
            {
              confirmButtonText: this.$t("src.views.apps.genel.yes"),
              cancelButtonText: this.$t("src.views.apps.genel.no"),
              type: "warning",
            }
          ).then(() => {
            try {
              this.editDialogLoading = true;
              projeService
                .projeGuncelle(
                  this.projeForm.adSoyad,
                  this.projeForm.eposta,
                  this.projeForm.telefon,
                  this.editDialogData.projeID
                )
                .then((response) => {
                  if (response.status) {
                    this.vazgec(formName);
                    if (this.yetkiTuru == 1) {
                      this.getProjeListesi(this.selectIcon);
                    }
                    if (this.yetkiTuru == 2) {
                      this.getBirimYoneticininProjeListesi(this.selectIcon);
                    }
                  }
                  notification.Status("success", this, response.msg);
                  localStorage.setItem("userDataBGSurec", response.token);
                  this.editDialogLoading = false;
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else notification.Status("errorMsg", this, error.errMsg);
                  } else {
                    //notification.Status(503, this)
                  }
                  this.editDialogLoading = false;
                });
            } catch (e) {
              // notification.Status("danger", this, "An error occurred during the update process.")
              this.editDialogLoading = false;
            }
          });
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            });
          }
          this.editDialogLoading = false;
        }
      });
    },
    changeProjeDurum(selection, durum) {
      this.$confirm(
        "Proje durumunu güncellemek istediğinizden emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      ).then(() => {
        try {
          this.stateLoading = true;
          var item = { durum: durum };
          projeService
            .projeGuncelle1(selection.projeID, item)
            .then((response) => {
              if (response.status == 200) {
                if (this.yetkiTuru == 1) {
                  this.getProjeListesi();
                }
                if (this.yetkiTuru == 2) {
                  this.getBirimYoneticininProjeListesi();
                }
              }
              this.stateLoading = false;
              notification.Status("success", this, response.msg);
              localStorage.setItem("userDataBGSurec", response.token);
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.stateLoading = false;
            });
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
          this.stateLoading = false;
        }
      });
    },
    getProjeSayisi() {
      try {
        projeService
          .projeSayisi(this.selectIcon)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
            }
            this.total = parseInt(response.data);
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.total = 0;
              }
            } else {
              //notification.Status(503, this)
            }
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer number.")
      }
    },
    getProjeListesi() {
      try {
        this.loading = true;
        projeService
          .projeListesi(
            this.selectIcon,
            this.postPage,
            this.yetkiTuru == 1 ? this.sizes : "",
            10
          )
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.projeList = response.data;
              this.total = parseInt(response.count);
              /*
            if(this.yetkiTuru == 1){
                this.projeList = response.data;
                this.total = parseInt(response.count);
            }

            if(this.yetkiTuru == 2){
               this.projeList = response.data.filter((proje) => {
                    return proje.yoneticiID == this.yoneticiID
               })
               this.total = parseInt(this.projeList.length);
            }*/
            }
            this.loading = false;
          })
          .catch((err) => {
            this.projeList = [];
            this.total = 0;
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.projeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    getBirimYoneticininProjeListesi() {
      try {
        this.loading = true;
        kapsamService
          .projeListesi(this.selectIcon, this.postPage, this.sizes)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.projeList = response.data;
              this.total = parseInt(response.count);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.projeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    startUpdate() {
      this.projeForm.adSoyad = this.editDialogData.adSoyad;
      this.projeForm.eposta = this.editDialogData.mail;
      this.projeForm.telefon = this.editDialogData.telefon;
      this.projeForm.tc = this.editDialogData.tc;
      // this.projeForm.dogumTarihi = moment(this.editDialogData.dogumTarihi).format("DD-MM-YYYY");
    },
    updatePage(data) {
      this.$store.commit("changeProjeUpdateData", data.projeID);
      functions.routeSayfa("Proje Güncelle", this);
    },
    routeProje(name, scope) {
      if (scope) {
        this.$store.commit("changeProjeUpdateData", scope);
      }
      functions.routeSayfa(name, this);
    },
    indexMethod(index) {
      return (this.page - 1) * this.sizes + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      if (this.yetkiTuru == 1) {
        this.getProjeListesi();
      }
      if (this.yetkiTuru == 2) {
        this.getBirimYoneticininProjeListesi();
      }
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      if (this.yetkiTuru == 1) {
        this.getProjeListesi();
      }
      if (this.yetkiTuru == 2) {
        this.getBirimYoneticininProjeListesi();
      }
      this.firmaAdiAra = "";
      this.adAra = "";
      this.alanAra = "";
      this.konumAra = "";
      this.ilAra = "";
      this.ilceAra = "";
      this.baslangicTarihiAra = "";
      this.bitisTarihiAra = "";
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      if (this.yetkiTuru == 1) {
        this.getProjeListesi();
      }
      if (this.yetkiTuru == 2) {
        this.getBirimYoneticininProjeListesi();
      }
    },
  },
};
</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}
</style>
<style lang="scss" scoped>
.islem-area {
  visibility: visible !important;
  display: flex;
  gap: 10px;
}

.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;
}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

/*
    .el-table .el-table__cell.is-right{
        position:fixed
    }*/

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
<style>
/*
    .tablo{
        background-color: rgb(59, 123, 179); /*rgb(148, 197, 240); 
    }*/

.tablo th.el-table__cell > .cell {
  background-color: aliceblue;
}

.icTablo .el-table .cell {
  line-height: 30px !important;
}

.el-table thead {
  line-height: 30px !important;
}
</style>
